import AspectWrapper from 'components/wrappers/AspectWrapper';
import Badges from 'components/products/ProductCard/Badges';
import Image from 'components/Image';
import Link from 'components/base/Link';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import { above } from 'utils/mediaqueries';
import colors from 'config/theme/colors';
import ratios from 'config/theme/ratios';
import styled from 'libs/styled';

const Wrapper = styled('div')``;

const StyledBadges = styled(Badges)`
    position: absolute;
    top: 8px;
    left: 8px;

    ${above.lg} {
        top: 16px;
        right: 16px;
        left: auto;
    }
`;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

const ArticleCard = ({
    category = '',
    contentStyles = {},
    date = '',
    heading,
    image,
    imageStyles = {},
    ratio = ratios.vertical,
    to = '',
    ...rest
}) => (
    <Wrapper {...rest}>
        <StyledLink to={to}>
            <AspectWrapper backgroundColor={colors.placeholder} position="relative" ratio={ratio} {...imageStyles}>
                <Image
                    alt={heading}
                    height="100%"
                    objectFit="cover"
                    sizes={['100vw', '33.33vw']}
                    src={{ url: image.url, width: [375, 768, 1024, 1440] }}
                />
                {category && <StyledBadges badges={[{ text: category, theme: 'white' }]} />}
            </AspectWrapper>

            {(heading || date) && (
                <Content width="100%" {...contentStyles}>
                    {date && <Paragraph size="xxs">{date}</Paragraph>}
                    {heading && (
                        <Paragraph as="h3" size="xs" fontWeight={500} marginTop={date ? ['4px', null, '8px'] : null}>
                            {heading}
                        </Paragraph>
                    )}
                </Content>
            )}
        </StyledLink>
    </Wrapper>
);

ArticleCard.propTypes = {
    category: PropTypes.string,
    contentStyles: PropTypes.object,
    date: PropTypes.string,
    heading: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imageStyles: PropTypes.object,
    ratio: PropTypes.number,
    to: PropTypes.string,
};

export default ArticleCard;
