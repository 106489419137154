import { above, below } from 'utils/mediaqueries';

import Above from 'components/breakpoints/Above';
import ArticleCard from 'components/ArticleCard';
import Below from 'components/breakpoints/Below';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import { animations } from 'config/theme/transitions';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';
import useAboveBreakpoint from 'hooks/useAboveBreakpoint';
import withAnimation from 'utils/withAnimation';
import { wysiwygProp } from 'utils/proptypes/modules/textProps';

const Wrapper = styled('div')``;

const HeadingAndButtons = styled('div')`
    margin-bottom: 16px;

    ${above.sm} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;
    }
`;

const Grid = styled('ul')`
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        ${above.sm} {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
        }
    }
`;

const Article = withAnimation(styled('li')`
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;

    ${above.sm} {
        width: 33.33%;
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    &[data-animate='true'] {
        opacity: ${({ animateIn }) => (animateIn ? 1 : 0)};
        transform: ${({ animateIn }) =>
            animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[0]})`};
        transition-property: all;
        transition-duration: ${animations.primary.duration}ms;
        transition-timing-function: ${animations.primary.timingFunction};

        ${above.md} {
            transform: ${({ animateIn }) =>
                animateIn ? 'translateY(0px)' : `translateY(${animations.primary.translate[3]})`};
        }
    }

    @supports (display: grid) {
        width: auto !important;
        max-width: none !important;
        flex: none !important;
        min-width: 0;

        ${below.sm} {
            &:nth-of-type(odd):last-of-type {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }
`);

const ArticleFlow = ({ articles = [], buttons = [], heading, headingTag = 'h2', textColor = '' }) => {
    const isAboveSm = useAboveBreakpoint('sm');
    const showButtons = buttons?.length > 0;

    return (
        <Wrapper color={textColor}>
            <HeadingAndButtons>
                {heading && (
                    <Heading withAnimation size="lg" as={headingTag} m="0">
                        {heading}
                    </Heading>
                )}
                {showButtons && (
                    <Above breakpoint="sm" render={() => <ButtonsList withAnimation buttons={buttons} pt="16px" />} />
                )}
            </HeadingAndButtons>
            <Grid>
                {articles?.map((article, index) => (
                    <Article
                        key={article?.id}
                        transitionDelay={[
                            `${animations.primary.delay * (index % 2)}ms`,
                            `${animations.primary.delay * (index % 3)}ms`,
                        ]}
                    >
                        <ArticleCard
                            image={isAboveSm ? article.backgroundLandscape : article.backgroundPortrait}
                            {...article}
                        />
                    </Article>
                ))}
            </Grid>
            {showButtons && (
                <Below breakpoint="sm" render={() => <ButtonsList withAnimation buttons={buttons} pt="16px" />} />
            )}
        </Wrapper>
    );
};

ArticleFlow.propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object).isRequired,
    buttons: buttonsProp,
    heading: PropTypes.string,
    headingTag: PropTypes.string,
    tag: PropTypes.string,
    text: wysiwygProp,
    textColor: PropTypes.string,
};

export default ArticleFlow;
